import axios from 'axios';
import { getToken, getAuthorization } from '@/utils/auth';
const Authorization = getAuthorization() || '';
const refreshToken = getToken() || '';

import { base_url } from '@/utils/BASE_URL';
const server = base_url.server

//获取媒体文件列表
function getMediaFileList(stId, missionBatch, folderName) {
  return axios({
    method: 'GET',
    url: `${server}/media/OssMediaManage/queryFolderList`,
    headers: { Authorization, refreshToken },
    params: {
      stId,
      missionBatch,
      folderName
    },
  }).then($result)
}

//下载资源
function getDownloadInAdvanceFile(fileList, callback) {
  return axios({
    method: 'GET',
    url: `${server}/media/OssMediaManage/downloadMediaFileZip`,
    headers: { Authorization, refreshToken },
    responseType: 'blob',
    params: {
      fileList
    },
    onDownloadProgress: callback
  }).then($result).catch($result)
}

/**
 * @description 删除文件
 * @param fileList:<String>
 * @return Promise:<Promise>
 *   */
function removeMediaFiles({ fileList = '' }) {
  return axios({
    method: 'GET',
    url: `${server}/media/OssMediaManage/batchDelete`,
    headers: { Authorization, refreshToken },
    params: {
      fileList
    },
  }).then($result).catch($result)
}

//返回结果
function $result(response) {
  const { code, data: res, status } = response;
  if (status === 200) {
    return res
  } else {
    throw new Error(res)
  }
}


//单文件复制
function cloneMediaFile({ destinationObject, sourceObject }) {
  return axios({
    method: 'GET',
    url: `${server}/media/OssMediaManage/copyObject`,
    headers: { Authorization, refreshToken },
    params: {
      destinationObject,
      sourceObject
    }
  }).then($result).catch($result)
}

//创建文件夹
function createFolder({ destinationObject, folderName }) {
  return axios({
    method: 'GET',
    url: `${server}/media/OssMediaManage/newFolder`,
    headers: { Authorization, refreshToken },
    params: {
      destinationObject,
      folderName
    }
  }).then($result).catch($result)
}

function getMediaConfig(stId) {
  return axios({
    method: 'GET',
    url: `${server}/media/OssMediaManage/queryMediaConfig?stId=${stId}`,
    headers: { Authorization, refreshToken },
  }).then($result).catch($result)
}

function updateMediaBucketConfig(params) {
  return axios({
    method: 'POST',
    url: `${server}/media/OssMediaManage/MediaStorageConfig`,
    headers: { Authorization, refreshToken },
    data: {
      ...params
    }
  }).then($result).catch($result)
}

export {
  getMediaFileList,
  getDownloadInAdvanceFile,
  removeMediaFiles,
  cloneMediaFile,
  createFolder,
  getMediaConfig,
  updateMediaBucketConfig
}