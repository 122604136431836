<template>
  <div class="download-file-progress-wrapper">
    <div class="full-width">
      <span
        :class="['expand', progress === 100 ? '' : 'progress-interim']"
        :style="{ width: `${progress}%` }"
      ></span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    progress: {
      type: Number,
      default: 0
    }
  }
};
</script>

<style lang="scss" scoped>
@mixin compatible($attribute, $value) {
  -webkit-#{$attribute}: $value;
  -moz-#{$attribute}: $value;
  -ms-#{$attribute}: $value;
  -o-#{$attribute}: $value;
}
.download-file-progress-wrapper {
  width: 100%;
  padding: 7px 4px;
  .full-width {
    width: 100%;
    height: 1px;
    box-shadow: 0px 0px 10px 1px rgba(0, 198, 255, 0.7);

    .expand {
      width: 30px;
      height: 100%;
      display: block;
      background-color: #2187e7;

      &.progress-interim {
        transition: 0.3s;
      }
    }
    // @include compatible(animation, fullexpand 10s ease-out);
  }
  // @keyframes fullexpand {
  //   0% {
  //     width: 0px;
  //   }
  //   100% {
  //     width: 100%;
  //   }
  // }
  // @-moz-keyframes fullexpand {
  //   0% {
  //     width: 0px;
  //   }
  //   100% {
  //     width: 100%;
  //   }
  // }
  // @-webkit-keyframes fullexpand {
  //   0% {
  //     width: 0px;
  //   }
  //   100% {
  //     width: 100%;
  //   }
  // }
}
</style>
