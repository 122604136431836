<template>
  <el-card class="results-show-wrapper page-container">
    <el-form slot="header" :inline="true" size="small">
      <el-form-item label="站点名称">
        <!-- @change="handleChange(query.siteID)" -->
        <el-select v-model="query.siteID" filterable clearable>
          <el-option v-for="site in siteList" :key="site.siteID" :value="site.siteID" :label="site.siteName"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-input v-model="query.fileName" clearable placeholder="请输入文件名" v-if="false"></el-input>
      </el-form-item>
      <el-form-item v-if="hasDate">
        <el-date-picker v-model="query.date" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-input v-model="query.missionBatch" clearable placeholder="请输入任务批次号"></el-input>
      </el-form-item>
      <el-form-item v-if="hasType">
        <el-select v-model="query.type">
          <el-option label="测试类型" value="1"></el-option>
          <el-option label="测试1" value="2"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item class="grid-change-wrap" v-if="false">
        <div v-for="view in views" :key="view.type" @click="handleChangeView(view.type)">
          <i :class="view.icon"></i>
        </div>
      </el-form-item>
      <el-form-item>
        <el-button icon="el-icon-search" type="primary" @click="handleQuery(query)"> 搜索 </el-button>
      </el-form-item>
      <el-form-item>
        <el-button @click="drawer = true" type="primary" v-if="downloading"> 查看下载进度 </el-button>
      </el-form-item>
      <el-form-item style="float: right">
        <el-button icon="el-icon-download" type="primary" @click="getDownloadInAdvanceFile"> 下载选中 </el-button>
        <el-button icon="el-icon-delete" type="danger" @click="handleRemoveAllOptions(downloadInAdvance)"> 删除选中 </el-button>
      </el-form-item>
    </el-form>
    <!--  -->
    <el-table v-if="!isGrid" ref="dragTable" row-key="fileName" fit :header-cell-style="headStyle" :data="fileList" :height="tHeight" @selection-change="handleSelectionChange" @row-click="handleClickRow" @row-contextmenu="handleRowContextmenu">
      <template slot="empty">
        暂无数据!
        <!-- <el-empty :image-size="100" description="暂无数据" /> -->
      </template>
      <el-table-column width="60px" align="center" v-if="false">
        <template>
          <i class="iconfont icon-move"></i>
        </template>
      </el-table-column>
      <el-table-column type="selection" width="60px" align="center"></el-table-column>
      <el-table-column label="缩略图" width="80px">
        <template slot-scope="scope">
          <img :src="scope.row.fileType == 1 ? (suffix === 'png' ? scope.row.filePreview : scope.row.fileShowThumbnail) : pic" style="width: 60px" />
        </template>
      </el-table-column>
      <el-table-column label="文件名称" align="center">
        <template slot-scope="scope">
          <el-link v-if="scope.row.fileType == 1" type="warning" @click="handlePreview(scope.row)">
            {{ scope.row.fileName }}
          </el-link>
          <el-link v-else @click="handleOpenFile(scope.row)" type="primary">
            {{ scope.row.fileName }}
          </el-link>
        </template>
      </el-table-column>
      <el-table-column label="文件类型" prop="fileType" align="center">
        <template slot-scope="scope">
          <el-tag size="mini" :type="scope.row.fileType === 1 ? 'success' : 'warning'">
            {{ scope.row.fileType === 1 ? '文件' : '文件夹' }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column label="大小" prop="fileSize" align="center">
        <template slot-scope="scope">
          {{ formatFileSize(scope.row.fileSize) }}
        </template>
      </el-table-column>
      <el-table-column label="创建时间" prop="fileLastModified" align="center"></el-table-column>
      <el-table-column label="操作" align="center" width="200">
        <template slot-scope="{ row }">
          <el-button type="text" icon="el-icon-download" @click="handleDownloadFile(row)"> 下载 </el-button>
          <el-button type="text" icon="el-icon-edit-outline" v-if="false">编辑</el-button>
          <el-button type="text" icon="el-icon-delete" style="color: #f40" @click="handleDelete(row.fileFullUrl)" v-if="row.fileType === 1"> 删除 </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-row class="pagination-bar" v-if="false">
      <el-pagination background layout="total, prev, pager, next" :current-page="page" :page-size="size" @current-change="handleCurrentChange" :total="total"></el-pagination>
    </el-row>
    <div class="grid-wrap" v-if="false">
      <div :class="[!isGrid ? 'list' : 'grid']">
        <div class="grid-item">
          <img src="~@/assets/images/folder.png" alt="1" />
          <p class="subtitle">这是我的测试文件</p>
        </div>
        <div class="grid-item">
          <img src="~@/assets/images/folder.png" alt="1" />
          <p class="subtitle">这是我的测试文件</p>
        </div>
      </div>
    </div>
    <el-dialog :close-on-click-modal="false" title="文件预览" :visible="dialogVisiblePreview" @close="handleClose">
      <video :src="mediaSource" v-if="suffix === 'mp4'" width="100%" controls></video>
      <img :src="mediaSource" alt="" v-else style="width: 100%" />
    </el-dialog>
    <el-drawer :show-close="true" :wrapperClosable="false" :visible.sync="drawer" :direction="direction" :before-close="handleCloseDrawer">
      <div class="sky-progress-wrap" :key="Math.random()">
        <sky-progress :progress="progess"></sky-progress>

        <p class="download-message">
          <span class="文件名">{{ downloadFileName }}</span>
          <span>
            {{ gross }}
            <span class="line-between">/</span>
            {{ sourceCount }}
          </span>
        </p>
      </div>
    </el-drawer>
    <ul class="sky-menu" ref="SkyMenu">
      <li @click="handleCopy" v-if="fileType === 1">
        <i class="el-icon-document-copy"></i>
        复制
      </li>
      <div v-else>
        <li :class="{ 'menu-item-disabled': !cloneRow.sourceObject }" @click="handlePaste">粘贴</li>
        <li :class="{ 'menu-item-disabled': !filePath && !this.touchRow.fileFullUrl }" @click="handleNewFile">
          <i class="el-icon-folder-add"></i>
          新建文件夹
        </li>
      </div>
    </ul>
  </el-card>
</template>

<script>
import SkyProgress from './component/progress.vue';
import pic from '@/assets/images/folder.png';
import { getSiteGroup } from '@/api/site.js';
import { getMediaFileList, getDownloadInAdvanceFile, removeMediaFiles, cloneMediaFile, createFolder } from '@/api/media/index.js';
import statusInclude from '@/utils/statusCode';
import Sortable from 'sortablejs';
import getHashCode from '@/utils/hash.js';
export default {
  name: 'Grid',
  components: {
    SkyProgress,
  },
  data() {
    return {
      total: 0,
      gross: 0,
      sourceCount: 0,
      progess: 0,
      page: 1,
      size: 13,
      total: 0,
      direction: 'btt',
      fileName: '',
      fileType: 2,
      tHeight: '',
      downloadInAdvance: '',
      downloadFileName: '',
      mediaSource: '',
      touchRow: '', //被选择的行
      cloneRow: {
        destinationObject: '',
        sourceObject: '',
        fileName: '',
      },
      folderInformation: {
        destinationObject: '', //目标文件夹
        folderName: '', //创建文件夹名称
      },
      downloading: false,
      hasDate: false,
      hasType: false,
      isGrid: false,
      sortable: null,
      drawer: false,
      dialogVisiblePreview: false,
      pic: pic,
      siteID: 'ST_00000017',
      views: [
        {
          icon: 'el-icon-picture',
          type: 'picture',
        },
        {
          icon: 'el-icon-s-unfold',
          type: 'list',
        },
        {
          icon: 'el-icon-menu',
          type: 'grid',
        },
      ],
      fileList: [],
      siteList: [],
      oldList: [],
      newList: [],
      query: {
        fileName: '',
        date: '',
        type: '',
        missionBatch: '',
      },
    };
  },
  computed: {
    suffix() {
      return this.fileName.substring(this.fileName.lastIndexOf('.') + 1);
    },
    filePath() {
      return this.$route.query.fileName;
    },
    hasFilePath() {
      return !this.filePath && !this.touchRow.fileFullUrl;
    },
  },
  created() {
    window.addEventListener('resize', this.getHeight);
    window.addEventListener('click', this.toggerRightMenu);
    this.getHeight();
    this.goTargetFile({ siteID: this.query.siteID });
    this.getSiteGroup();
    // this.getMediaFileList({ siteID: this.query.siteID });
  },
  mounted() {
    const that = this;
    document.querySelector('.el-card__body').oncontextmenu = function (event) {
      const parentLeft = this.getBoundingClientRect().left;
      that.touchRow = {};
      event.preventDefault();
      that.$refs.SkyMenu.style.left = event.pageX - parentLeft + 11 + 'px';
      that.$refs.SkyMenu.style.top = event.pageY - 50 + 'px';
      that.$refs.SkyMenu.style.display = 'inline-block';
    };
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.getHeight);
    window.removeEventListener('click', this.toggerRightMenu);
  },
  watch: {
    $route: {
      deep: true,
      handler(newVal, oldVal) {
        const { siteID, fileName, missionBatch } = newVal.query;
        this.getMediaFileList({ siteID, fileName, missionBatch });
      },
    },
  },
  methods: {
    toggerRightMenu(event) {
      event.preventDefault();
      event.stopPropagation();
      this.fileType = 2;
      if (event.target.className === 'menu-item-disabled') return;
      this.$refs.SkyMenu.style.display = 'none';
    },

    getHeight() {
      this.tHeight = document.getElementsByTagName('body')[0].offsetHeight - this.$store.state.settings.offset;
    },
    goTargetFile({ siteID, fileName, missionBatch }) {
      this.$router.push({
        path: 'results',
        query: { siteID, fileName, missionBatch },
      });
    },
    // 处理表头布局样式
    headStyle() {
      return 'text-align:center';
    },
    //复制源文件路径
    handleCopy() {
      const { fileFullUrl, fileName } = this.touchRow;
      this.cloneRow.sourceObject = fileFullUrl;
      this.cloneRow.fileName = fileName;
    },
    //粘贴
    async handlePaste() {
      if (this.hasFilePath || !this.cloneRow.sourceObject) return;
      const targetPath = this.touchRow.fileFullUrl || this.filePath;
      this.cloneRow.destinationObject = targetPath + this.cloneRow.fileName;
      try {
        const { code, reason } = await cloneMediaFile(this.cloneRow);
        if (!statusInclude(code)) return this.$message.error(`粘贴失败:${reason}`);
        this.$message.success('粘贴成功');
      } catch (error) {
        return this.$message.error(`粘贴失败:${error}`);
      }
    },
    //新建文件夹
    handleNewFile() {
      if (!this.$route.query.fileName && !this.touchRow.fileFullUrl) return;
      this.folderInformation.destinationObject = this.touchRow.fileFullUrl || this.$route.query.fileName;
      this.$prompt('请输入文件夹名称', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        // inputPattern: /(^[a-zA-Z][a-zA-Z\s]{0,20}[a-zA-Z]$)/,
        // inputErrorMessage: '文件夹名称不规范',
      })
        .then(async ({ value }) => {
          this.$message({ type: 'success', message: '你的邮箱是: ' + value });
          this.folderInformation.folderName = value;
          const { code, reason } = await createFolder(this.folderInformation);
        })
        .catch((error) => {
          this.$message({ type: 'info', message: '取消输入' });
        });
    },
    //搜索
    handleQuery(query) {
      if (!query.siteID) return this.$message.warning('请选择站点');
      this.goTargetFile(query);
    },
    // 修改页码
    handleCurrentChange() {},
    setupSortable() {
      const className = '.el-table__body-wrapper > table > tbody';
      const el = this.$refs.dragTable.$el.querySelectorAll(className)[0];
      this.sortable = new Sortable(el, {
        group: {
          name: 'shared',
          pull: 'clone',
          put: false, // 不允许拖拽进这个列表
        },
        sort: false,
        ghostClass: 'sortable-ghost',
        setDate: function (dataTransfer) {
          dataTransfer.setData('Text', '');
        },
        onEnd: (evt) => {
          const { oldIndex, newIndex } = evt;
          const targetRow = this.fileList.splice(evt.oldIndex, 1)[0];
          this.fileList.splice(evt.newIndex, 0, targetRow);
          const tempIndex = this.newList.splice(evt.oldIndex, 1)[0];
          this.newList.splice(evt.newIndex, 0, tempIndex);
        },
      });
    },
    getMediaSource(row) {
      const { fileName } = row;
    },
    handleChangeView() {
      this.isGrid = !this.isGrid;
    },
    handleCloseDrawer(done) {
      done();
    },
    handleSelectionChange(fileGroup) {
      this.gross = fileGroup.reduce((per, cur) => {
        per += cur.fileSize;
        return per;
      }, 0);
      this.downloadInAdvance = fileGroup.map(({ fileFullUrl }) => fileFullUrl).join(',');
    },
    //点击表格行
    handleClickRow(row, column, event) {
      event.stopPropagation();
    },
    // 右键了表格时
    handleRowContextmenu(row, column, event) {
      event.preventDefault();
      event.stopPropagation();
      this.fileType = row.fileType;
      this.touchRow = row;
      this.$refs.SkyMenu.style.left = event.pageX - 50 + 'px';
      this.$refs.SkyMenu.style.top = event.pageY - 50 + 'px';
      this.$refs.SkyMenu.style.display = 'inline-block';
    },
    //预览媒体文件
    handlePreview(row) {
      const { fileName, filePreview } = row;
      this.fileName = fileName;
      this.mediaSource = filePreview;
      this.dialogVisiblePreview = true;
    },
    handleClose() {
      this.dialogVisiblePreview = false;
    },
    //进入文件夹中
    handleOpenFile(row) {
      const { fileFullUrl, fileName, folderPrefixUrl } = row;
      const [filePath] = folderPrefixUrl.split('/');
      this.goTargetFile({ siteID: filePath, fileName: fileFullUrl });
      return;
    },
    handleChange(stId) {
      this.getMediaFileList({ siteID: stId });
    },
    //删除单个文件/文件夹
    async handleDelete(fileName = '') {
      this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(async () => {
          await this.removeOptionMdeiaFiles({ fileList: [fileName] });
        })
        .catch(() => {
          this.$message({ type: 'info', message: '已取消删除' });
        });
    },

    //删除所有选中文件
    async handleRemoveAllOptions(fileNameList = '') {
      if (!fileNameList.length) return this.$message.warning('请选择所需要删除的文件');
      this.$confirm('此操作将永久删除所选中的文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(async () => {
          await this.removeOptionMdeiaFiles({ fileList: fileNameList });
        })
        .catch(() => {
          this.$message({ type: 'info', message: '已取消删除' });
        });
    },
    async removeOptionMdeiaFiles({ fileList = '' }) {
      const { code, reason } = await removeMediaFiles({ fileList });
      if (!statusInclude(code)) return this.$message.error(`删除失败:${reason}`);
      this.$message.success('删除成功');
      this.goTargetFile(this.query);
    },

    /**
     * 格式化文件大小
     * @param {*} value
     */
    formatFileSize(size) {
      let value = Number(size);
      if (size && !isNaN(value)) {
        const units = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB', 'BB'];
        let index = 0;
        let k = value;
        if (value >= 1024) {
          while (k > 1024) {
            k = k / 1024;
            index++;
          }
        }
        return `${k.toFixed(2)}${units[index]}`;
      }
      return '-';
    },
    async getSiteGroup() {
      const { code, data, reason } = await getSiteGroup();
      this.siteList = data.list;
    },
    async getMediaFileList({ siteID, missionBatch, fileName } = {}) {
      this.fileList = [];
      const format = false;
      const { code, data, reason } = await getMediaFileList(siteID, missionBatch, fileName);
      if (!statusInclude(code)) return siteID ? this.$message.error(`请求失败:${reason}`) : '';
      format &&
        data.forEach((file) => {
          if (file.fileType === 1) {
            file.fileSize = file.fileSize * 1024;
          }
        });
      this.fileList = data;
      this.oldList = this.fileList.map((v) => v.fileName);
      this.newList = this.oldList.slice();
      this.$nextTick(() => {
        // this.setupSortable();
      });
    },
    //单个文件下载
    handleDownloadFile({ fileFullUrl, fileSize, fileType }) {
      this.downloadInAdvance = fileFullUrl;
      this.gross = fileSize;
      this.getDownloadInAdvanceFile();
    },

    //下载媒体文件 zip
    async getDownloadInAdvanceFile() {
      this.progess = 0;
      if (!this.downloadInAdvance) return this.$message.warning('请选中需要下载的文件');
      this.downloading = true;
      const firstName = getHashCode();
      const suffix = 'zip';
      const fileName = `${firstName}.${suffix}`;
      this.downloadFileName = fileName;
      this.drawer = true;
      const onUploadProgress = (ProgressEvent) => {
        this.sourceCount = ProgressEvent.loaded;
        let progressPercent = parseInt((ProgressEvent.loaded / this.gross) * 100);
        this.progess = progressPercent;
        //理想状态下的完全下载成功
        if (this.progess === 100) {
        }
      };
      const blob = await getDownloadInAdvanceFile(this.downloadInAdvance, onUploadProgress);
      this.downloading = false;
      //处理进度条
      this.sourceCount = this.gross; //下载完成后不论结果如何直接将预测值覆盖实际下载结果
      this.progess = 100; //下载进度设置为100
      this.$message({ type: 'success', message: '文件下载成功' });

      const href = URL.createObjectURL(blob); //创建新的URL表示指定的blob对象
      const a = document.createElement('a'); //创建a标签
      a.style.display = 'none';
      a.href = href; // 指定下载链接
      a.download = fileName; //指定下载文件名
      a.click(); //触发下载
      URL.revokeObjectURL(a.href); //释放URL对象
    },
  },
};
</script>

<style lang="scss" scoped>
.results-show-wrapper {
  @import './index.scss';
}
</style>
